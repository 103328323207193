<template>
  <nuxt-link
    v-if="linkData?.internal"
    :to="linkData.url === '/home' ? '/' : linkData.url"
    :download="linkData?.download ? 'download' : null"
    class="atom-link"
    :class="{ 'atom-link--has-chevron': showChevron }"
    @click="linkData.download ? null : sendClickEvent($event)"
  >
    <span class="atom-link__content"><slot /></span>
    <AppIcon
      v-if="showChevron"
      class="atom-link__chevron"
      icon="icon-up"
      :is-raw="true"
    />
  </nuxt-link>
  <a
    v-else-if="linkData?.url"
    :href="linkData.url"
    :rel="linkData.blank ? 'noopener noreferrer' : null"
    :target="linkData.blank || linkData.download ? '_blank' : null"
    :download="linkData.download ? 'download' : null"
    class="atom-link"
    :class="{ 'atom-link--has-chevron': showChevron }"
    @click="linkData.download ? null : sendClickEvent($event)"
  >
    <slot />
    <AppIcon
      v-if="showChevron"
      class="atom-link__chevron"
      icon="icon-up"
      :is-raw="true"
    />
  </a>
</template>

<script setup>
const props = defineProps({
    /* Props for storyblok usage */
    data: {
        type: Object,
        default: () => {},
    },

    /* Props for app usage */
    url: {
        type: [String, Boolean],
        default: false,
    },

    blank: {
        type: Boolean,
        default: false,
    },

    text: {
        type: String,
        default: '',
    },

    ariaDescription: {
        type: String,
        default: '',
    },

    linktype: {
        type: String,
        default: '',
    },

    anchor: {
        type: String,
        default: '',
    },
    showChevron: {
        type: Boolean,
        default: false,
    },
});

/*
  Prepare Link data from different props
*/
const linkData = computed(() => {
    let url = props.data?.link?.cached_url
      || props.data?.cached_url
      || props.data?.link?.url
      || props.data?.link?.email
      || props.url;

    const internal = isInternalLink(url);
    const isRooted = url.includes('..');

    if (internal && !isRooted) {
        url = `/${url}`;
    }

    const description = props.data?.aria_description || props.ariaDescription;

    const linktype = props.data?.link?.linktype || props.linktype;
    const isEmail = linktype === 'email';
    const isAsset = linktype === 'asset';

    const blank = (internal === false || props.blank) && !isEmail;
    const anchor = props.data?.link?.anchor || props.anchor;

    if (linktype === 'email') {
        url = `mailto:${url}`;
    }

    if (anchor) {
        url = `${url === '/home' ? '/' : url}#${anchor}`;
    }

    return {
        url,
        description,
        download: isAsset,
        email: isEmail,
        internal,
        blank,
    };
});

const { sendEvent } = useGTM();
const sendClickEvent = ($event) => {
    const element = $event.target;
    const classes = element.classList;
    const { id } = element;
    const tmp = document.createElement('a');
    tmp.href = linkData.value.url;

    sendEvent({
        action: 'click',
        link_id: id,
        link_classes: classes,
        link_domain: tmp.host,
        link_text: element.innerText,
        link_url: linkData.value.url,
        outbound: !linkData.value.internal,
    });
};
</script>

<style lang="scss">
.atom-link--has-chevron {
    display: flex;
}
.atom-link__content {
    @include desktop {
        width: 85%
    }

    .app-header-quickshop__logo & {
        display: flex;
        height: 100%;
    }
}

.atom-link__chevron {
    width: 0.9rem;
    color: currentColor;
    transform: rotate(90deg) translateY(-15px);
}
</style>
